import React from 'react';
import WomanImg from '../../assets/img/natalia_home.svg';
import ym from 'react-yandex-metrika';

const Hero = () => {
  return (
    <section
      id='home'
      // style={{ backgroundImage: `url(${Background})`}}
      className=" flex items-center bg-secondary bg-no-repeat bg-cover bg-center lg:bg-cover lg:bg-center lg:bg-no-repeat pt-32 pb-8 overflow-hidden xl:pt-0 lg:pt-24 md:pt-40 lg:pb-0 md:pb-8"
    >
      <div className='container mx-auto h-full'>
        <div className='flex items-center h-full '>
          <div className='flex-1 flex flex-col items-center lg:items-start'>
            <h1 className='font-primary text-4xl leading-[44px] d:text-5xl md:leading-tight lg:text-7xl lg:leading-[1.2] font-bold md:tracking-[-2px] lg:text-left md:text-left text-center sm:text-center'>
              Я занимаюсь продажей и покупкой <br /> недвижимости.
            </h1>
            <p className='font-secondary pt-4 pb-8 md:pt-6 md:pb-12 max-w-[480px] text-base text-center lg:text-left'>
              Самый важный ресурс в жизни - время. <br /> Выбрав меня, в качестве вашего представителя по вопросу недвижимости, вы экономите время, деньги и нервы.
            </p>
            <div className='space-x-8 flex'>
              <a className='' href='tel:+79108112880' >
                <button className='font-secondary btn btn-md bg-accent hover:bg-secondary-hover md:btn-lg transition-all'
                onClick={()=>{
                  window.ym(91070959,'reachGoal','btn-phone')
                  console.log('click btn-phone');
                }}>
                  Связаться со мной
                </button>
              </a>
              <a href='/contact'>
                  <button className='font-secondary btn btn-md bg-accent hover:bg-secondary-hover md:btn-lg transition-all'
                  onClick={()=>{
                    window.ym(91070959,'reachGoal','btn-contact')
                    console.log('click btn-contact');
                  }}>
                    Оставить заявку
                  </button>
              </a>
            </div>
          </div>
          <div className='hidden lg:flex flex-1 justify-end items-end h-5/6	'>
            <img src={WomanImg} alt='' />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
