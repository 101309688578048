import React from 'react';
// import components
import Projects from './Projects';

const Portfolio = () => {
  return (
    <section id='portfolio' className='section bg-primary min-h-[1400px] pt-20 pb-20'>
      <div className='container mx-auto'>
        <div className='flex flex-col items-center text-center '>
          <h2 className='text-#1C1D24 font-primary font-bold section-title before:content-portfolio relative before:absolute before:opacity-40 before:-top-[2rem] before:-left-3/4 before:hidden before:lg:block'>
            Категории возможных услуг
          </h2>
          <p className='font-secondary subtitle'>
            Во вкладках ниже вы можете ознакомиться с услугами, которые я могу предоставить вам, как эксперт по недвижимости.
          </p>
        </div>
        <Projects />
      </div>
      <br />
      <br />
      <div className='flex flex-col items-center text-center'>
        <h1 className='font-primary section-title'>
          Заинтересовало?
        </h1>
        <a className='flex flex-col container mx-auto items-center justify-center' href='/contact'>
          <button className='font-secondary btn btn-md bg-accent hover:bg-secondary-hover transition-all'>
            Оставить запрос
          </button>
        </a>
        <br />
        <a className='flex flex-col container mx-auto items-center justify-center' href='tel:+79108112880'>
          <button className='font-secondary btn btn-md bg-accent hover:bg-secondary-hover transition-all'>
            Связаться по телефону
          </button>
        </a>
      </div>
    </section>
  );
};

export default Portfolio;
