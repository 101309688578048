import React from 'react'
import Footer from './Homepage/Footer';
import Header from './Homepage/Header';
import Services from './Services/Portfolio';

const allone = () => {
  return (
    <div>
        <Header />
        <br></br>
        <br></br>
        <Services />
        <Footer />
    </div>
  )
}

export default allone