import React from 'react';


const pers = () => {

  return (
    <section id='testimonials' className='section bg-primary pt-20 pb-8'>
      <div className='container mx-auto'>
        <div className='flex flex-col items-center text-center'>
          <h2 className='font-primary section-title before:content-testimonials relative before:absolute before:opacity-40 before:-top-[2rem] before:-left-64 before:hidden before:lg:block'>
            Согласие об обработке персональных данных
          </h2>
          <p className='font-secondary container'>
            <p> 
                Данное соглашение об обработке персональных данных разработано в соответствии с законодательством Российской Федерации. Все лица, предоставившие сведения, составляющие персональные данные на данном сайте, а также разместившие иную информацию, обозначенными действиями подтверждают свое согласие на обработку персональных данных и их передачу оператору обработки персональных данных.
            </p>
            <br />
            <p>
            Под персональными данными Гражданина понимается нижеуказанная информация: общая информация (имя, отчество, фамилия, телефон и email адрес);
            посетители сайта направляют свои персональные данные для получения услуги риэлтора, как пример:
             <ul>
                <li>- продажа жилого помещения</li>
                <li>- покупка жилого помещения</li>
                <li>- обмен жилого помещения</li>
                <li>- сопровождение сделки</li>
                <li>- сопровождение сделки с использованием кредитных средств (включая ипотеку)</li>
                <li>- приватизация жилого помещения</li>
             </ul>
             и другие.
             </p>
            <br />
            <p>
            Гражданин, принимая настоящее Соглашение, выражает свою заинтересованность и полное согласие, что обработка его персональных данных может включать в себя следующие действия: сбор, систематизацию, накопление, хранение, уточнение (обновление, изменение), использование, уничтожение.
            </p>
            <br />
            <p>
            Гражданин гарантирует: информация, им предоставленная, является полной, точной и достоверной; при предоставлении информации не нарушается действующее законодательство Российской Федерации, законные права и интересы третьих лиц; вся предоставленная информация заполнена Гражданином в отношении себя лично.
            </p>
          </p>
        </div>
      </div>
      <br />
      <br />
      <div className='flex mt-2 mb-4 space-x-20 flex-col items-center text-center'>
        <a href='/'>
          <button className='font-secondary btn btn-md bg-accent hover:bg-secondary-hover transition-all '>
            Главная страница
          </button>
        </a>
      </div>
    </section>
  );
};

export default pers;
