import React from 'react';
import ScrollToTop from './ScrollToTop'
// import navigation data
import { navigation } from '../../data';

// import Link
import { Link as RouterLink } from 'react-router-dom';


const Nav = () => {


  return (
    <nav>
      <ul className='flex space-x-8 text-[15px]'>
        {navigation.map((item, idx) => {
          return (
            <li
              className='text-white hover:text-accent cursor-pointer'
              key={idx}
            >
              <RouterLink
                to={item.href}
                preventScrollReset={true}
                duration={500}
                offset={-70}
                className={item.className}
              >
                {item.name}
              </RouterLink>
              <ScrollToTop />
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Nav;
