import React from 'react'
import Header from './Homepage/Header';
import Hero from './Homepage/Hero';
import About from './Homepage/About';
import Contact from './Homepage/Contact';
import Footer from './Homepage/Footer';
import Countup from './Homepage/Skills';


const allone = () => {
  return (
    <div>
        <Header />
        <Hero />
        <About />
        <Countup />
        <Contact />
        <Footer />
    </div>
  )
}

export default allone