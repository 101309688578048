import React from 'react';

// import social data
import { social } from '../../data';

import {
  FaMapMarkerAlt,
} from 'react-icons/fa';


const Footer = () => {
  return (
    <footer className='bg-tertiary py-6'>
      <div className='container mx-auto'>
        <div className='flex flex-col lg:flex-row space-y-6 lg:space-y-0 items-center justify-between'>
          <div className='font-secondary text-xs'>
            <p className='text-paragraph flex space-x-2'>
              <FaMapMarkerAlt /> 
              <p>
                Россия, Ярославль
              </p>
            </p>
          </div>
          <div className='flex space-x-6 items-center justify-center'>
            {social.map((item, index) => {
              const { href, icon } = item;
              return (
                <a className='text-accent text-base hover:text-2xl' href={href} key={index}>
                  {icon}
                </a>
              );
            })}
          </div>
          <p className='text-paragraph font-primary opacity-80 text-[12px]'>
            <p className='font-secondary text-xs'>* Компания Meta является запрещенной <br /> на территории РФ организацией</p>
            &copy; 2022 <a className='underline' href="https://t.me/Ivan_Rezvetsov">Ivan Rezvetsov</a>. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
