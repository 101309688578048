import React, { useRef } from "react";

const SecondForm = ({ formValues, onChange, option }) => {
  const form = useRef();
  return (
    <div className="w-full max-w-sm">
      <div className='flex flex-col items-center text-center mx-auto'>
        <form ref={form} className="bg-slate-300  shadow-md px-14 pt-16 rounded-md ">
          <div className="grid gap-4 place-content-center items-center">
            <h1 className="text-gray-700 pb-8 font-bold text-2xl">Дополнительная информация</h1>
          </div>
          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="Address"
            >
              Какой этаж?
            </label>
            <input
              className="text-center shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="floor"
              name="floor"
              type="number"
              placeholder="25"
              value={formValues.address}
              onChange={onChange}
            ></input>
          </div>
          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="State"
            >
              Количество комнат
            </label>
            <select
              className="text-center block shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="rooms"
              name="rooms"
              onChange={onChange}
              value={formValues.city}
            >
              {option.map((state) => {
                return (
                  <option key={state.id} value={state.id}>
                    {state.name}
                  </option>
                );
              })}
            </select>
          </div>{" "}
          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="Zip"
            >
              Площадь
            </label>
            <input
              className="text-center shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="meters"
              type="text"
              name="meters"
              required='true'
              value={formValues.zip}
              onChange={onChange}
              placeholder="45-56"
            ></input>
          </div>
          <div className="mb-6 pb-4 ">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="Zip"
            >
              Доп информация
            </label>
            <input
              className="text-center shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="info"
              type="text"
              name="info"
              value={formValues.zip}
              onChange={onChange}
              placeholder="С хорошим ремонтом"
            ></input>
          </div>
          <div className="flex items-center justify-between"></div>
        </form>
      </div>
    </div>
  );
};

export default SecondForm;
