import React from 'react'
import Header from './Homepage/Header';
import Politic from './politic/pers';
import Footer from './Homepage/Footer';


const allone = () => {
  return (
    <div>
        <Header />
        <br></br>
        <br></br>
        <Politic />
        <Footer />
    </div>
  )
}

export default allone