import React from 'react';
import { Route, Routes} from 'react-router-dom';

import BackTopBtn from './components/BackTopBtn';

import Homepage from './components/Homepage';
import Utilities from './components/Utilities';
import Testimonials from './components/Testim';
import Contact from './components/Request';
import Politic from './components/Politic';

function App() {
  return (
    <>
      <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/services" element={<Utilities />} />
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/politic" element={<Politic />} />
      </Routes>
      <BackTopBtn />
    </>
  )
}

export default App;
