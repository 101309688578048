import React from 'react';

// import icons
import { social } from '../../data';

const Socials = () => {
  return (
    <ul className='flex space-x-6 '>
      {social.map((item, index) => {
        return (
          <li
            className='flex justify-center items-center text-accent'
            key={index}
          >
            <a className='hover:text-2xl text-base' href={item.href} title="Запрещенная на территории РФ организация">
              {item.icon}
            </a>
          </li>
        );
      })}
      <p className='w-0 h-0 text-accent'>
        *
      </p>
    </ul>
  );
};

export default Socials;
