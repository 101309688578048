import React,{ useRef, useState} from 'react';
import {
    FaPhone,
    FaMailBulk,
  } from 'react-icons/fa';
import emailjs from '@emailjs/browser';
import { useNavigate } from "react-router-dom";
import Modal from 'react-modal';


const Contact = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [topic, setTopic] = useState('');
  const [text, setText] = useState('');
  let navigate = useNavigate();
  const form = useRef();


  const handleSubmit = (e) => {
    e.preventDefault();

    setUsername('');
    setEmail('');
    setTopic('');
    setText('');
    openModal();
    emailjs.sendForm('service_voxchc7', 'template_3ahsfyh', form.current , 'VaxYI2_NrELmSggzk')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  const routeChange = () =>{
    navigate(0);
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  return (
    <section className='section bg-primary pt-20 pb-16' id='contact'>
      <div className='container mx-auto'>
        <div className='flex flex-col items-center text-center'>
          <h2 className='font-primary section-title before:content-contact relative before:absolute before:opacity-40 before:-top-7 before:-left-40 before:hidden before:lg:block'>
            Связаться со мной
          </h2>
          <p className='font-secondary subtitle'>
            Вы можете оставить мне заявку с помощью формы ниже.<br/> Я постараюсь связаться с вами, как можно скорее.
          </p>
        </div>
        <div className='flex flex-col lg:gap-x-8 lg:flex-row '>
          <div className='font-secondary hidden md:flex flex-1 flex-col items-start space-y-8 mb-12 lg:mb-0 lg:pt-2'>

            <div className='flex flex-col lg:flex-row gap-x-4 xl:visible'>
              <div className='text-accent rounded-sm w-14 h-14 flex items-start justify-center mt-2 mb-4 lg:mb-0 text-2xl'>
                <FaMailBulk />
              </div>
              <div>
                  <h4 className='font-body text-xl mb-1'>Связаться по почте</h4>
                  <a href='mailto:Np184682@rambler.ru'>
                    <p className='mb-1 text-paragraph'>Np184682@rambler.ru</p>
                  </a>
                  <p className='text-accent font-normal '></p>
              </div>
            </div>

            <div className='flex flex-col lg:flex-row gap-x-4'>
              <div className='text-accent rounded-sm w-14 h-14 flex items-start justify-center mt-2 mb-4 lg:mb-0 text-2xl'>
                <FaPhone />
              </div>
                <div>
                  <h4 className='font-body text-xl mb-1'>Телефон</h4>
                  <a href='tel:+79201146306'>
                    <p className='mb-1 text-paragraph'>+79108112880</p>
                  </a>
                  <p className='text-accent font-normal '></p>
                </div>
            </div>

          </div>
          <form ref={form} onSubmit={(event) => {handleSubmit(event);}} className='space-y-8 w-full max-w-[780px]'>
            <div className='flex gap-8'>
              <input className='input' id='user' name='user' type='text' required='true' onChange={event => setUsername(event.target.value)} value={username} placeholder='Ваше имя' />
              <input className='input' id='mail' name='mail' type='email' required='true' onChange={event => setEmail(event.target.value)} value={email} placeholder='Ваш email адрес' />
            </div>
            <input className='input' id='theme' name='theme' required='true' type='text' onChange={event => setTopic(event.target.value)} value={topic} placeholder='Тема письма' />
            <textarea
              className='textarea'
              name='area'
              placeholder='Ваше сообщение'
              required='true'
              id='area'
              onChange={event => setText(event.target.value)} value={text}
            ></textarea>
            <div className='flex mt-8 mb-4 items-start space-x-4'>
                <button
                  type='submit'
                  required='true'
                  className='font-secondary btn btn-md bg-accent hover:bg-secondary-hover transition-all'
                  onClick={()=>{
                    window.ym(91070959,'reachGoal','btn-send-form')
                    console.log('click btn-send-form');
                  }}>
                  Отправить запрос
                </button>
            </div>
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={routeChange}
              style={customStyles}
            >
              <h2 className="text-accent py-1 font-secondary text-center">Спасибо, запрос отправлен!</h2>
              <a href="/">
                  <button
                    type="button"
                    className="absolute  top-1 right-0 rounded-full w-7  text-accent"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={closeModal}
                  >
                      <span aria-hidden="true">&times;</span>
                  </button>
                </a>
            </Modal>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
