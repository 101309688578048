import React from "react";
import { achivements } from '../../data';
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

class ReactImageGalleryTest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: []
    };
  }

  render() {
    return (
    <section id='collage' className='section bg-secondary pt-8'>
      <div className='container mx-auto'>
        <div className='flex flex-col items-center text-center'>
          <h2 className='font-primary section-title before:content-testimonials relative before:absolute before:opacity-40 before:-top-[2rem] before:-left-64 before:hidden before:lg:block'>
              Достижения и сертификаты
          </h2>
          <p className='font-secondary subtitle'>
              Мои отмеченные и подтвержденные  достижения в области недвижимости.
          </p>
          <div className="flex flex-col container mx-auto">
              <ImageGallery showPlayButton={false} showThumbnails={true} items={achivements} />
          </div>
          </div>
      </div>
    </section>
    );
  }
}
export default ReactImageGalleryTest;
