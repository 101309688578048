import React from "react";

const ThirdForm = ({ onChange, formValues }) => {
  return (
    <div className="w-full max-w-sm">
      <div className='flex flex-col items-center text-center'>
        <form className="bg-slate-300  shadow-md px-14 pt-16 pb-10 rounded-md ">
          <div className="grid gap-4 place-content-center items-center">
            <h1 className="text-gray-700 font-bold text-2xl">Контактные данные</h1>
            <p className="">Вы можете указать оба вида связи, либо один удобный для вас :)</p>
            <br/>
          </div>
          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="username"
            >
              Как к вам обращаться?
            </label>
            <input
              className="text-center shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="username"
              name="username"
              required='true'
              onChange={onChange}
              type="text"
              placeholder="Имя"
            ></input>
          </div>
          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="email"
            >
              Email адрес
            </label>
            <input
              className="text-center shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              name="email"
              value={formValues.password}
              onChange={onChange}
              type="email"
              required='true'
              placeholder="Email"
            ></input>
          </div>
          <div className="mb-6 pb-4 ">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="confirmPassword"
            >
              Телефон
            </label>
            <input
              className="text-center shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="phone"
              name="phone"
              type="tel"
              value={formValues.confirmPassword}
              onChange={onChange}
              placeholder="+7(000)000-00-00"
            ></input>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ThirdForm;
