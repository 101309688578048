import React, { useState } from "react";
import emailjs from '@emailjs/browser';
import FirstForm from "./FormComponents/FirstForm";
import SecondForm from "./FormComponents/SecondForm";
import ThirdForm from "./FormComponents/ThirdForm";
import Modal from 'react-modal';
import { useNavigate } from "react-router-dom";


const Contact = () => {
  const formList = ["FirstForm", "SecondForm", "ThirdForm"];
  let navigate = useNavigate();
  const formLength = formList.length;

  const [page, setPage] = useState(0);

  const handlePrev = () => {
    setPage(page === 0 ? formLength - 1 : page - 1);
  };
  const handleNext = () => {
    setPage(page === formLength - 1 ? 0 : page + 1);
  };

  const initialValues = {
    object: "",
    place: "Дзержинский",
    floor: "",
    rooms: "1",
    meters: "",
    info: "",
    username: "",
    email: "",
    phone: "",
  };
  const [values, setValues] = useState(initialValues);

  const handleForms = () => {
    switch (page) {
      case 0: {
        return (
          <div>
            <FirstForm
              formValues={values}
              onChange={onChange}
              option={states}>
            </FirstForm>
          </div>
        );
      }
      case 1: {
        return (
          <SecondForm
            formValues={values}
            onChange={onChange}
            option={rooms}
          ></SecondForm>
        );
      }
      case 2: {
        return <ThirdForm formValues={values} onChange={onChange}></ThirdForm>;
      }
      default:
        return null;
    }
  };

  const states = [
    { id: "0", name: "Дзержинский" },
    { id: "1", name: "Ленинский" },
    { id: "2", name: "Кировский" },
    { id: "3", name: "Фрунзенский" },
    { id: "4", name: "Заволжский" },
    { id: "5", name: "Красноперекопский" },
    { id: "6", name: "Ярославская область" },
  ];


  const rooms = [
    { id: "1", name: "1" },
    { id: "2", name: "2" },
    { id: "3", name: "3" },
    { id: "4", name: "4" },
    { id: "5", name: "5 и более" },
  ];


  const [error, setError] = useState(false);

  const handleSubmit = async (e) => {
    if(values.object === null || values.object === undefined || values.object === "") {
      alert("Необходимо заполнить обязательные поля:\n- объект недвижимости\n- ваше имя\n- email адрес или телефон")
      console.log("Error with object", values.object)
    }
    else if (values.username === null || values.username === undefined || values.username === "") {
      alert("Необходимо заполнить обязательные поля:\n- объект недвижимости\n- ваше имя\n- email адрес или телефон")
      console.log("Error with username", values.username)
    }
    else if ( values.email === "" && values.phone === "" )
    {
      alert("Необходимо заполнить обязательные поля:\n- объект недвижимости\n- ваше имя\n- email адрес или телефон")
      console.log("bad email or phone")
    }
    else if (values.email !== "" && values.phone === "" || values.email === "" && values.phone !== "" || values.email !== "" && values.phone !== "") {
      console.log("All right with email:", values.email , "and phone", values.phone)

      emailjs.send('service_voxchc7', 'template_nd9xi8y', values , 'VaxYI2_NrELmSggzk')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

      openModal();
      e.preventDefault();
      const response = await setTimeout(() => {
        console.log("form", values.object);
      }, 2000);
      return response;
    }
    else
      {
      console.log("Something bad with form or values")
    }
  };

  const onChange = (e) => {
    const { name, value, type, checked } = e.target;
    setValues({ ...values, [name]: type === "checkbox" ? checked : value });
  };


  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }


  const routeChange = () =>{
    let path = `/`;
    navigate(path);
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  return (

    <section className='section bg-primary pt-20' id='contact-form'>
      <div className='container mx-auto'>
        <div className='flex flex-col items-center text-center'>
          <h2 className='font-primary section-title before:content-contact relative before:absolute before:opacity-40 before:-top-7 before:-left-40 before:hidden before:lg:block'>
            Составьте необходимый запрос
          </h2>
          <p className='font-secondary subtitle'>
            Вы можете оставить мне заявку с помощью формы ниже. Укажите необходимые параметры недвижимости и я свяжусь с вами в ближайшее время.
          </p>
        </div>
        <div className="font-secondary grid gap-4 place-content-center items-center place-items-center ">
          <div className="flex-1">{handleForms()}</div>
          <div className="grid grid-cols-2 gap-4 place-content-center items-center">
            <button
              onClick={handlePrev}
              className="font-secondary rounded-md btn btn-md bg-accent hover:bg-secondary-hover transition-all"
              disabled={page === 0}
            >
              Назад
            </button>
            {page === 2 ? (
            <span>
              <button
                type="submit"
                onClick={(event) => {handleSubmit(event); window.ym(91070959,'reachGoal','btn-send-req')
                console.log('click btn-send-req');}}
                className="font-secondary rounded-md btn btn-md bg-accent hover:bg-secondary-hover transition-all">
                Отправить
              </button>
              <Modal
                isOpen={modalIsOpen}
                onRequestClose={routeChange}
                style={customStyles}
                // closeTimeoutMS={5000}
              >
                <h2 className="text-accent py-3 font-secondary text-center">Спасибо, заявка отправлена!</h2>
                <a href='/'>
                  <button className="font-secondary btn btn-md bg-accent hover:bg-secondary-hover transition-all items-center w-72">Главная страница</button>
                </a>
                <a href="/contact">
                  <button
                    type="button"
                    className="absolute  top-1 right-0 rounded-full w-7  text-accent"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={closeModal}
                  >
                      <span aria-hidden="true">&times;</span>
                  </button>
                </a>
              </Modal>
            </span>
            ) : (
              <button
                onClick={handleNext}
                className="font-secondary rounded-md btn btn-md bg-accent hover:bg-secondary-hover transition-all"
              >
                Вперед
              </button>
            )}
          </div>
          <p className="text-center text-gray-500 text-xs">
          &copy;Все права защищены.<br/>
          <a className="underline" href="/politic">
            Политика обработки персональных данных.
          </a>
        </p>
        </div>
      </div>
    </section>
  );
};

export default Contact;
