import React from 'react';

// import img
import Image from '../../assets/img/natalia_about.jpg';

const About = () => {
  return (
    <section className='section bg-primary' id='about'>
      <div className='container mx-auto'>
        <div className='flex flex-col xl:flex-row gap-24'>
          <img
            className='object-cover h-full w-[566px] md:mx-auto rounded-2xl lg:mx-auto'
            src={Image}
            alt=''
          />
          <div className='flex flex-col items-center text-center lg:items-center lg:text-center '>
            <div className='flex flex-col'>
              <h2 className='font-primary text-3xl lg:text-4xl font-medium lg:font-extrabold mb-3 before:content-about relative before:absolute before:opacity-40 before:-top-[2rem] before:hidden before:lg:block'>
                Наталья Шефер
              </h2>
              <p className='font-secondary mb-4 text-accent'>
                Эксперт по недвижимости
              </p>
              <hr className='mb-8 opacity-5' />
              <p className='mb-8 text-1.5xl font-secondary'>
              Приветствую! Меня зовут Наталья Шефер и я являюсь экспертом в сфере недвижимости! 
              <br />
              <br />
              Стаж работы в банковской сфере и на рынке недвижимости Ярославля и Ярославской области больше 20 лет. 
              <br />
              <br />
              Преимущественно занимаюсь покупкой, продажей, обменом квартир, сдачей квартир и другой недвижимости в аренду, провожу сделки с загородной недвижимостью (покупка, продажа дач, коттеджей, земельных участков). 
              <br />
              <br />
              Помогаю с расчетом и получением ипотеки, выбором банка, хорошо знаю тонкости и детали ипотечных сделок. 
              <br />
              <br />
              Оплату получаю за результат, по факту состоявшейся сделки. Без авансов и предоплат. Консультации - бесплатно. 
              <br />
              <br />
              Качество моих услуг - вы можете почитать отзывы о моей работе клиентов, которым я помогла в решении вопросов с недвижимостью.
             
              </p>
            </div>
            
            <div className='flex mt-2 mb-4 items-start space-x-20'>
              <a href='/services'>
                <button className='font-secondary btn btn-md bg-accent hover:bg-secondary-hover transition-all'>
                  Услуги
                </button>
              </a>
              <a href='/testimonials'>
                <button className='font-secondary btn btn-md bg-accent hover:bg-secondary-hover transition-all'>
                  Отзывы
                </button>
              </a>
              
            </div>

          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
