import React from 'react'
import Header from './Homepage/Header';
import Contact from './Contact/Contact';
import Footer from './Homepage/Footer';


const allone = () => {
  return (
    <div>
        <Header />
        <br></br>
        <br></br>
        <Contact />
        <Footer />
    </div>
  )
}

export default allone