import React from 'react';

// import components
import TestiSlider from './TestiSlider';

const Testimonials = () => {

  return (
    <section id='testimonials' className='section bg-primary pt-20 pb-8'>
      <div className='container mx-auto'>
        <div className='flex flex-col items-center text-center'>
          <h2 className='font-primary section-title before:content-testimonials relative before:absolute before:opacity-40 before:-top-[2rem] before:-left-64 before:hidden before:lg:block'>
            Что другие люди говорят обо мне
          </h2>
          <p className='font-secondary subtitle'>
            Довольные люди и их успех, есть самое важное для меня. Мы вместе идем к поставленной задаче.
            И добиваемся необходимого результата в срок.
          </p>
        </div>
        <TestiSlider />
      </div>
      <br />
      <br />
      <div className='flex mt-2 mb-4 space-x-20 flex-col items-center text-center'>
        <a href='https://uslugi.yandex.ru/profile/NatalyaShefer-1793444'>
          <button className='font-secondary btn btn-md bg-accent hover:bg-secondary-hover transition-all '>
            Еще отзывы 
          </button>
        </a>
      </div>
    </section>
  );
};

export default Testimonials;
