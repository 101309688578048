//  icons
import {
  FaTelegramPlane,
  FaMailBulk,
  FaMapMarker,
  FaInstagram,
  FaWhatsapp,
  FaFacebook,
} from 'react-icons/fa';

import { Icon24LogoVk } from '@vkontakte/icons';

import achive1 from './assets/img/achivements/5th.jpg';
import achive2 from './assets/img/achivements/6th.jpg';
import achive3 from './assets/img/achivements/3rd.jpg';
import achive4 from './assets/img/achivements/1st.jpg';
import achive5 from './assets/img/achivements/2nd.jpg';
import achive6 from './assets/img/achivements/4th.jpg';

// projects images
import Key1 from './assets/img/utils/key1.jpg';
import sale from './assets/img/utils/sale.jpg';
import flat from './assets/img/utils/flat.jpg';
import rent from './assets/img/utils/rent.jpg';
import vkp from './assets/img/utils/vkp.jpg';
import Remote from './assets/img/utils/diana1.jpg';
import Sopr from './assets/img/utils/sopr.jpg';



// navigation
export const navigation = [
  {
    name: 'Главная',
    href: '/',
    className: 'hover:text-xl font-primary transition-all duration-300',
  },
  {
    name: 'Услуги',
    href: '/services',
    className: 'hover:text-xl font-primary transition-all duration-300',
  },
  {
    name: 'Портфолио',
    href: '/testimonials',
    className: 'hover:text-xl font-primary transition-all duration-300',
  },
  {
    name: 'Оставить заявку',
    href: '/contact',
    className: 'hover:text-xl font-primary transition-all duration-300',
  },
];

// nav social
export const social = [
  {
    icon: <FaWhatsapp />,
    href: 'https://wa.me/79108112880',
  },
  {
    icon: <FaTelegramPlane />,
    href: 'https://t.me/+79108112880',
  },
  {
    icon: <Icon24LogoVk />,
    href: 'https://vk.com/nshefer75',
  },
  {
    icon: <FaFacebook />,
    href: 'https://www.facebook.com/profile.php?id=100003650640898', 
  },
  {
    icon: <FaInstagram />,
    href: 'https://instagram.com/nata.shefer',
  },
];


// utils
export const projectsData = [
  {
    id: '1',
    image: flat,
    name: 'Продажа квартиры под ключ',
    category: 'Продажа',
    description: 'Презентация квартиры потенциальным покупателям и подготовка документов к продаже квартиры. Составление договора купли-продажи и обеспечение безопасности расчетов.',
  },
  {
    id: '2',
    image: Key1,
    name: 'Покупка квартиры под ключ',
    category: '',
    description: 'Полный комплекс услуг от подбора подходящего объекта и рекомендаций, что и почему взять лучше, до получения документов на собственность!',
  },
  {
    id: '3',
    image: sale,
    name: 'Сделки с загородной недвижимостью',
    category: '',
    description: 'Работаю с домами, дачами и коттеджами в Ярославле и области. Сопровождаю на всех этапах сделки и действую в Ваших интересах.',
  },

  {
    id: '4',
    image: rent,
    name: 'Сдача объекта недвижимости в аренду',
    category: '',
    description: 'Полный комплекс услуг, от показов и до финального оформления договора в моем присутствии.',
  },
  {
    id: '5',
    image: vkp,
    name: 'Срочный выкуп недвижимости',
    category: '',
    description: 'Готова ПРЯМО СЕЙЧАС купить Вашу квартиру в Ярославле, в кротчайшие сроки! В любом состоянии: с долгами по ЖКХ или в ипотеке. Расчеты сразу, в день сделки за наличные деньги!',
  },
  {
    id: '6',
    image: Remote,
    name: 'Подбор всех видов недвижимости при переезде из другого города',
    category: '',
    description: 'Работа по подбору основана на прозрачности, защите от недобросовестных людей, юридическом сопровождении и экономии вашего времени!',
  },
  {
    id: '7',
    image: Sopr,
    name: 'Сопровождение сделки на любом этапе',
    category: '',
    description: 'Готова на любом этапе подключиться к процессу или сделке - минимизировать Ваши риски в отношении покупки или продажи объекта недвижимости!',
  },

];

// caregories
export const projectsNav = [
  {
    name: 'Все',
  },
  {
    name: 'Покупка-продажа',
  },
  // {
  //   name: 'Покупка',
  // },
  {
    name: 'Аренда-выкуп',
  },
  {
    name: 'Ипотека',
  },
];



// testimonials
export const testimonials = [
  {
    authorText:
      'Сверх всяких похвал Наталья Шефер - настоящий проффесионал своего дела. Рекомендую: высокая эффективность, короткие сроки реализации сделки, честность и ответственность',
    authorName: 'Федор Чевыкалов',
    authorPosition: 'Продажа 3-х комнатной квартиры под ключ',
  },
  {
    authorText:
      'Искала квартиру для собственного жилья. Квартира нужна была в короткие сроки, с чем за помощью обратилась к Наталье Павловне. Она помогла с поисками, понимая мои запросы и пункты, которые важны. Сделка проведена грамотно и быстро.',
    authorName: 'Анна Суздалева',
    authorPosition: 'Покупка жилья под ключ и в короткий срок',
  },
  {
    authorText:
      'Обращались к Наталье Павловне Шефер за помощью в поиске покупателей на квартиру. Остались очень довольны. Очень приятная в общении девушка, сразу почувствовали заботливое отношение к клиентам',
    authorName: 'Павел Практик',
    authorPosition: 'Продажа квартиры под ключ',
  },
  {
    authorText:
      'Выражаю огромную благодарность Шефер Наталье Павловне, за высокий профессионализм. Сделки по покупке и продаже квартир были проведены в короткие сроки, несмотря на сложности и обстоятельства. Наталья Павловна так же помогла в сборе необходимых документов и оформлении ипотеки. Спасибо за вашу работу!',
    authorName: 'Людмила Боброва',
    authorPosition: 'Продажа имеющегося жилья, покупка новой квартиры, помощь в оформлении ипотеки с выгодным процентом',
  },
  {
    authorText:
      'Хочу поблагодарить Шефер Наталью за помощь в сделке по выкупу доли в квартире. Сделка была непростая, но благодаря проффесионализму Натальи все прошло отлично. У меня не болела голова ни о нотариусе, ни о банке, ни о других органах. Наталья все сделала сама, грамотно и проффесионально!',
    authorName: 'Елена Гончаренко',
    authorPosition: 'Выкуп доли в квартире, сопровождение сделки под ключ',
  },
  {
    authorText:
      'Хочу выразить огромную благодарность Шефер Наталье за высококвалицифированную проделанную работу. Сделка по покупке двухкомнатной квартиры была проведена в короткие сроки, несмотря на множество сложностей и непредвиденных обстоятельств.',
    authorName: 'Артур Омельниченко',
    authorPosition: 'Покупка 2-х комнатной квартиры под ключ, в недельный срок',
  },
];



// achivements-certs
export const achivements = [
  {
    original: achive1,
    thumbnail: achive1
  },
  {
    original: achive2,
    thumbnail: achive2
  },
  {
    original: achive3,
    thumbnail: achive3
  },
  {
    original: achive4,
    thumbnail: achive4
  },
  {
    original: achive5,
    thumbnail: achive5
  },
  {
    original: achive6,
    thumbnail: achive6
  }
];

// contact
export const contact = [
  {
    icon: <FaMailBulk />,
    title: 'Остались ли у вас вопросы?',
    subtitle: 'Рада буду вам помочь.',
    description: 'Отправьте мне письмо на  hello@youremail.com',
  },
  {
    icon: <FaTelegramPlane />,
    title: 'Оставить заявку с параметрами',
    subtitle: '',
    description: '',
  },
  {
    icon: <FaMapMarker />,
    title: 'Местоположение',
    subtitle: 'Ярославль, Россия',
    description: '',
  },
];
