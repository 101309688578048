import React from 'react'
import Header from './Homepage/Header';
import Testimonials from './Testimonials/Testimonials';
import Contact from './Homepage/Contact';
import Footer from './Homepage/Footer';
import Collage from './Testimonials/Collage';

const allone = () => {
  return (
    <div>
        <Header />
        <br></br>
        <br></br>
        <Testimonials />
        <Collage />
        <Contact />
        <Footer />
    </div>
  )
}

export default allone