import React, { useState } from 'react';
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';
// import brands data


const Brands = () => {
  const [counterOn, setCounterOn] = useState(false);
  return (
    < ScrollTrigger onEnter={()=> setCounterOn(true)} onExit={()=> setCounterOn(false)}>
      <section className='bg-primary min-h-[100px] lg:justify-items-center'>
        <div
          className='container mx-auto pt-5 pb-5 text-center flex  items-center lg:justify-items-center flex-wrap justify-evenly'
        >
          <div className='shadow-2xl w-96 group border-2 rounded-full px-20 my-6 border-accent'>
            <h1 className='text-4xl font-primary font-bold flex items-center mb-2 flex-col'> 
              <span className='text-accent'>
              {counterOn && <CountUp start={0} end={200} duration={2} delay={0}/>}+
              </span>
              Проведенных <br /> сделок
            </h1>
          </div>

          <div className='shadow-2xl w-96 group border-2 rounded-full px-20 my-6 border-accent'>
            <h1 className='text-4xl font-primary font-bold flex items-center mb-2 flex-col'>
              <span className='text-accent'>
              {counterOn && <CountUp start={0} end={475} duration={2} delay={0}/>}+
              </span>
              Довольных <br /> клиентов
            </h1>
          </div>

          <div className='shadow-2xl w-96 group border-2 rounded-full px-20 my-6 border-accent'>
            <h1 className='text-4xl font-primary font-bold  flex items-center mb-2 flex-col'>
              <span className='text-accent'>
              {counterOn && <CountUp start={0} end={100} duration={2} delay={0}/>}%
              </span>
              Доверия <br /> людей
            </h1>
          </div>
        </div>

      </section>
    </ScrollTrigger>
  );
};

export default Brands;
